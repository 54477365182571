import { useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import Field from 'presentation/field';
import Submit from 'presentation/inputs/submit';

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const passwordField = {
  type: 'password',
  name: 'password',
  label: 'Password',
  validations: {
    realtime: true,
    rules: [
      {
        pattern: /[0-9]/,
        message: 'At least one number',
      },
      {
        pattern: /[A-Z]/,
        message: 'At least one uppercase letter',
      },
      {
        pattern: /^.{8,}$/,
        message: 'Minimum 8 characters',
      },
    ],
  },
};

function SimpleDialog(props: SimpleDialogProps): React.ReactElement {
  const { onClose, selectedValue, open } = props;
  const { type, name, label, validations } = passwordField;
  const [formData, setFormData] = useState({ password: { valid: false } });

  const handleClose = useCallback((): void => {
    setFormData({ password: { valid: false } });
    onClose(selectedValue);
  }, [setFormData, onClose, selectedValue]);

  const handleUpdate = useCallback(
    (e: HTMLInputElement, valid: boolean): void => {
      const field = Object();
      field[e.name] = {
        value: e.value,
        valid,
      };
      setFormData({ ...formData, ...field });
    },
    [setFormData, formData]
  );

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Set new password</DialogTitle>
      <DialogContent>
        <Field
          type={type}
          name={name}
          label={label}
          validations={validations}
          handleUpdate={handleUpdate}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>
          Cancel
        </Button>
        &ensp;
        <Submit isValid={formData.password.valid} value="Submit" handleSubmit={handleClose} />
      </DialogActions>
    </Dialog>
  );
}

export default function PasswordModal(): React.ReactElement {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('test');

  const handleClickOpen = useCallback((): void => {
    setOpen(true);
  }, []);

  const handleClose: (value: string) => void = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Change password
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </>
  );
}
