import { styled } from '@material-ui/core/styles';
import { Box, AppBar, Toolbar, Stack, Typography, Fab } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { useAuthDispatch, useAuthState, useAuthActions } from 'infrastructure/security';

const DRAWER_WIDTH = 200;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  backgroundColor: 'rgba(255,255,255,0.5)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function Header(): React.ReactElement | null {
  const { authentication } = useAuthState();
  const authActions = useAuthActions(useAuthDispatch());

  return (
    <RootStyle style={{ zIndex: 1 }}>
      <ToolbarStyle>
        <Typography color="primary">
          <strong>Logged as</strong>
          {authentication?.principal.name} ({authentication?.principal.username})
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <AccountPopover /> */}
          <Fab onClick={() => authActions.logout()} color="primary" size="small" aria-label="add">
            <ExitToApp />
          </Fab>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
