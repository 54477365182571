import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat'].join(','),
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.7rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.2rem',
    },
  },
  palette: {
    primary: {
      main: '#8d62ff',
    },
    secondary: {
      main: '#2cc1ee',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
          position: 'relative',
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          padding: '2rem',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' },
      },
      styleOverrides: {
        root: {
          padding: '10rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '10rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '12px 24px 24px',
        },
      },
    },
  },
});

const GlobalStyles: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default GlobalStyles;
