/* eslint-disable */
import React from 'react';
import {
  Container,
  Stack,
  Button,
  Typography,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Grow,
} from '@material-ui/core';

const TABLE_HEAD = [
  { label: 'Name', alignRight: false },
  { label: 'Status', alignRight: false },
  { label: '' },
];
const TABLE_BODY = [{ name: 'Report 1', status: 'Active' }];

const CustomCheckbox = () => (
  <Checkbox sx={{ color: '#6f1da3', '&.Mui-checked': { color: '#6f1da3' } }} />
);

export default class ReportsContainer extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Grow in>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Reports
            </Typography>
          </Stack>

          <Card>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <CustomCheckbox />
                    </TableCell>
                    {TABLE_HEAD.map((item, i) => (
                      <TableCell key={`th-${i}`}>{item.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TABLE_BODY.map((item, i) => (
                    <TableRow hover key={`tr-${i}`}>
                      <TableCell padding="checkbox">
                        <CustomCheckbox />
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Container>
      </Grow>
    );
  }
}
