import Validations from 'infrastructure/resources/validations';

export default [
  {
    type: 'text',
    name: 'username',
    label: 'Username',
    placeholder: '',
    required: true,
    validations: {
      rules: [
        {
          pattern: Validations.email,
          message: 'Invalid email',
        },
      ],
    },
  },
  {
    type: 'password',
    name: 'password',
    label: 'Password',
    required: true,
  },
];
