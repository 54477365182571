import { Button, CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

interface Props {
  value: string;
  loading?: boolean;
  handleSubmit: () => void;
  isValid?: boolean;
}

const SubmitInputComponent: React.FC<Props> = ({ value, isValid, loading, handleSubmit }) => {
  function onSubmit(): void {
    if (isValid) {
      handleSubmit();
    } else {
      // console.log("Preencha o formulário corretamente");
    }
  }

  function renderIcon(): React.ReactElement {
    let el = <></>;
    if (loading) {
      el = <CircularProgress color="inherit" size={15} />;
    } else if (isValid) {
      el = <CheckIcon />;
    }
    return <>&ensp;{el}</>;
  }

  return (
    <span>
      <Button color="primary" variant="contained" type="submit" onClick={() => onSubmit()}>
        {value}
        {renderIcon()}
      </Button>
    </span>
  );
};

export default SubmitInputComponent;
