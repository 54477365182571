import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';

interface ConfirmModalProps {
  open: boolean;
  title?: string;
  content?: string;
  confirm: () => void;
  cancel: () => void;
}

export default function ConfirmModal({
  open,
  title,
  content,
  confirm,
  cancel,
  children,
}: React.PropsWithChildren<ConfirmModalProps>): React.ReactElement {
  function handleConfirm(): void {
    confirm();
  }

  function handleCancel(): void {
    cancel();
  }

  return (
    <>
      {open && (
        <Dialog maxWidth="xs" fullWidth aria-labelledby="simple-dialog-title" open>
          <DialogTitle id="simple-dialog-title">{title || 'Confirm action'}</DialogTitle>
          <DialogContent>
            <Typography>{content}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleConfirm()}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </>
  );
}
