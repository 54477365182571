import { Authentication } from 'infrastructure/security/model';

export default class SecurityStorageHelper {
  static getAuthentication(): Authentication | null {
    const authenticationItem = localStorage.getItem('@App:authentication');
    return authenticationItem !== null ? (JSON.parse(authenticationItem) as Authentication) : null;
  }

  static setAuthentication(authentication: Authentication): void {
    localStorage.setItem('@App:authentication', JSON.stringify(authentication));
  }

  static removeAuthentication(): void {
    localStorage.removeItem('@App:authentication');
  }
}
