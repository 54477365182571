/* eslint-disable */
import axios from 'axios';
import { Either, left, right } from 'infrastructure/common/either';
import { PageableRequest, PageableResponse } from 'infrastructure/common/pageble';
import { UserDTO } from '../dto/user.dto';
import { UserService } from '../user.service';

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export class UserServiceMock implements UserService {
  getAll = async ({
    filter,
    page,
    rowsPerPage
  }: PageableRequest
  ): Promise<Either<Error, PageableResponse<UserDTO>>> => {
    await delay(2000);
    const response = await axios.get('/mocks/users.json');

    let rows = response.data;
    let total = response.data.length;

    if (filter && filter.search) {
      const filteredRows = rows.filter((it: any) => (
        it[filter.search.key] && 
        `${it[filter.search.key]}`.toLowerCase().indexOf(`${filter.search.value}`.toLowerCase()) > -1));
      rows = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      total = rows.length;
    }
    
    rows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const data = rows.map((it: any) => ({...it} as UserDTO));
    const pageableResponse = { total, rowsPerPage, page, data } as PageableResponse<UserDTO>;
    return right(pageableResponse);
  };

  getById = async (id: string): Promise<Either<Error, UserDTO>> => {
    await delay(1000);
    const response = await axios.get('/mocks/users.json');
    const match = response.data.find((user: UserDTO) => (user.id === id));
    if (!match) {
      left(Error('Nenhum encontrado'));
    }
    return right(match);
  };
}

export const userServiceMock = new UserServiceMock();
