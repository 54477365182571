import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';

export interface Props {
  onClose?: () => void;
  open: boolean;
  title?: string;
  content?: string;
}

export default function AlertModal(props: React.PropsWithChildren<Props>): React.ReactElement {
  const { children, onClose, open, title, content } = props;
  function handleClose(): void {
    if (onClose) {
      onClose();
    }
  }

  return (
    <>
      <Dialog maxWidth="xs" fullWidth aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">{title || 'Confirm action'}</DialogTitle>
        <DialogContent>
          <Typography>{content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => handleClose()}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
}
