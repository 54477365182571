import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Container, Stack, Typography, Card, Grow } from '@material-ui/core';
import { PlanFormComponent, Plan } from 'domain/plan';

import serviceContext from 'infrastructure/service/context';
import ArrowBackButton from 'presentation/layout/arrow-back-button';
import Spinner from 'presentation/layout/spinner';

const FormCard = styled(Card)`
  min-height: calc(100vh - 300px);
`;

export default function PlanShowContainer(): React.ReactElement {
  const { planService } = serviceContext;
  const navigate = useNavigate();
  const location = useLocation();

  const { planId } = useParams();
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState({} as Plan);

  function goBack(): void {
    if (location.state) {
      navigate(-1);
    } else {
      navigate('../');
    }
  }

  const getPlan = useCallback(async () => {
    if (planId) {
      setLoading(true);
      const planOrError = await planService.getById(planId);
      if (planOrError.isRight()) {
        setPlan(planOrError.value as Plan);
      }
      setLoading(false);
    }
  }, [planId, planService]);

  useEffect(() => {
    getPlan();
  }, [planId, getPlan]);

  const planFormProps = {
    plan,
    updatePlan: (planToUpdate: Plan): void => {
      console.log(planToUpdate); // eslint-disable-line no-console
    },
  };

  return (
    <Container maxWidth="xl">
      <Grow in>
        <div>
          <Stack direction="row" alignItems="center" mb={2}>
            <Typography variant="h1" gutterBottom>
              <ArrowBackButton onClick={() => goBack()} />
              Plan: {plan.name}
            </Typography>
          </Stack>
          <FormCard>
            {loading && <Spinner />}
            {!loading && <PlanFormComponent {...planFormProps} />}
          </FormCard>
        </div>
      </Grow>
    </Container>
  );
}
