import { useState } from 'react';
import SearchInput from 'presentation/inputs/search';
import SelectInput from 'presentation/inputs/select';
import { Button } from '@material-ui/core';

import { TableSearchProps } from './types';

export default function TableSearchComponent({
  term,
  field,
  fields,
  onSubmitSearch,
  onClearSearch,
  minLength,
}: TableSearchProps): React.ReactElement {
  const [searchField, setSearchField] = useState(field || fields[0].field);
  const [searchTerm, setSearchTerm] = useState(term || '');

  function submitSearch(): void {
    if (onSubmitSearch) {
      onSubmitSearch(searchField, searchTerm);
    }
  }
  function clearSearch(): void {
    if (onClearSearch) {
      onClearSearch();
    }
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitSearch();
      }}
      style={{ width: '100%', textAlign: 'right', marginBottom: '1rem' }}
    >
      <SearchInput
        minLength={minLength}
        name="search"
        placeholder="search"
        value={searchTerm}
        handleClear={() => clearSearch()}
        handleChange={(e) => setSearchTerm(e.value)}
      />
      &ensp;
      <SelectInput
        name="fields"
        size="small"
        options={fields.map((it) => ({ value: it.field, label: it.label }))}
        value={searchField}
        placeholder="field"
        handleChange={(e) => setSearchField(e.value)}
      />
      &ensp;
      <Button variant="contained" type="submit">
        Go
      </Button>
    </form>
  );
}
