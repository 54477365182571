import validations from '../../infrastructure/resources/validations';

export default {
  account: [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      value: '',
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      value: '',
      validations: {
        rules: [
          {
            pattern: validations.email,
            message: 'Email inválido',
          },
        ],
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'text',
      disabled: true,
      value: '',
    },
    {
      name: 'sky_code',
      label: 'SKY code',
      type: 'text',
      disabled: true,
      value: '',
    },
    {
      name: 'createdAt',
      label: 'Creation date',
      type: 'text',
      disabled: true,
      format: (v: string): string => new Date(v).toLocaleDateString('pt-BR'),
      value: '',
    },
  ],
  profiles: [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      disabled: true,
      value: 'Bruno',
    },
    {
      name: 'type',
      label: 'Type',
      type: 'text',
      disabled: true,
      value: 'parent',
    },
    {
      name: 'age',
      label: 'Age',
      type: 'text',
      disabled: true,
      value: '36',
    },
  ],
  subscription: [
    {
      name: 'partner',
      label: 'Billing platform ',
      type: 'text',
      disabled: true,
      value: '',
    },
    {
      name: 'subscribedAt',
      label: 'Subscription date',
      type: 'text',
      disabled: true,
      format: (v: string): string => new Date(v).toLocaleDateString('pt-BR'),
      value: '',
    },
    {
      name: 'plan',
      label: 'Plan/Value',
      type: 'text',
      disabled: true,
      value: 'Trial (R$0,00)',
    },
    {
      name: 'credit_card',
      label: 'Credit card',
      type: 'text',
      disabled: true,
      value: '',
    },
  ],
  devices: [
    {
      name: 'manufacturer',
      label: 'Manufacturer',
      type: 'text',
      disabled: true,
      value: '',
    },
    {
      name: 'platform',
      label: 'platform',
      hide: true,
      type: 'text',
      disabled: true,
      value: '',
    },
    {
      name: 'model',
      label: 'Model',
      type: 'text',
      disabled: true,
      value: '',
    },
    {
      name: 'version',
      label: 'Version',
      type: 'text',
      hide: true,
      disabled: true,
      value: '',
    },
    {
      name: 'language',
      label: 'Language',
      type: 'text',
      disabled: true,
      value: '',
    },
    {
      name: 'installedAt',
      label: 'Installed at',
      type: 'text',
      disabled: true,
      format: (v: string): string => new Date(v).toLocaleDateString('pt-BR'),
      value: '',
    },
  ],
};
