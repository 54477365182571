import { FormEvent, useState, useMemo, useCallback } from 'react';
import { SubmitInput } from 'presentation/inputs/';
import { Credentials } from 'infrastructure/security/model';

import Field from 'presentation/field';
import FormHelpers from 'infrastructure/helpers/form.helper';
import fields from './fields';

interface DoLogin {
  (credentials: Credentials): Promise<void>;
}

export default function LoginForm({
  loading,
  doLogin,
}: {
  loading: boolean;
  doLogin: DoLogin;
}): React.ReactElement {
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState({
    username: {},
    password: {},
  });

  const formHelpers = useMemo(() => new FormHelpers(formData), [formData]);

  const updateForm = useCallback(
    (e: HTMLInputElement, valid?: boolean): void => {
      const field = Object();
      field[e.name] = {
        value: e.value,
        valid,
      };
      setFormData({ ...formData, ...field });
      setIsValid(formHelpers.isValid());
    },
    [formData, formHelpers]
  );

  const submit = useCallback(async (): Promise<void> => {
    if (!isValid) {
      setIsDirty(true);
      return;
    }
    const data = formHelpers.parseForm();
    const credentials: Credentials = {
      username: data.username.value,
      password: data.password.value,
    };
    await doLogin(credentials);
  }, [isValid, formHelpers, doLogin]);

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      submit();
    },
    [submit]
  );

  return (
    <form onSubmit={onSubmit}>
      {fields.map((field) => (
        <Field
          key={field.label}
          required={field.required}
          type={field.type}
          dirty={isDirty}
          name={field.name}
          label={field.label}
          placeholder={field.placeholder}
          validations={field.validations ? field.validations : undefined}
          handleUpdate={updateForm}
        />
      ))}
      <br />
      <SubmitInput
        value="Submit"
        loading={loading}
        isValid={isValid}
        handleSubmit={() => submit()}
      />
    </form>
  );
}
