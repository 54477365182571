import SecurityStorageHelper from 'infrastructure/helpers/security-storage.helper';
import { Authentication } from './model';

export enum AuthActionType { // eslint-disable-line no-shadow
  LOGIN,
  LOGOUT,
}

export interface AuthAction {
  type: AuthActionType;
  payload?: Authentication;
}

export interface AuthState {
  logged: boolean;
  authentication?: Authentication;
}
const authentication = SecurityStorageHelper.getAuthentication();
export const initialState = { logged: authentication !== null, authentication } as AuthState;

export const AuthReducer = (state: AuthState, action: AuthAction): AuthState => {
  const { type, payload } = action;
  switch (type) {
    case AuthActionType.LOGIN:
      return {
        logged: true,
        authentication: payload,
      } as AuthState;
    case AuthActionType.LOGOUT:
      return {
        logged: false,
      } as AuthState;
    default:
      return state;
  }
};
