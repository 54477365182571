import { useCallback } from 'react';
import { TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { extractRowsPerPageOptionsValue } from 'infrastructure/common/pageble';
import { TablePaginationProps } from './types';

export default function TablePaginationComponent({
  total,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}: TablePaginationProps): React.ReactElement {
  const handlePageChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
      if (onPageChange) {
        onPageChange(newPage);
      }
    },
    [onPageChange]
  );

  const handleRowsChange = useCallback(
    (v: React.ChangeEvent<HTMLInputElement>): void => {
      if (onRowsPerPageChange) {
        onRowsPerPageChange(Number(v.target.value));
      }
    },
    [onRowsPerPageChange]
  );

  const rowsPerPageOptions: number[] = extractRowsPerPageOptionsValue();

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsChange}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </TableRow>
    </TableFooter>
  );
}
