import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Container, Stack, Typography, Card, Grow } from '@material-ui/core';
import { UserFormComponent, User } from 'domain/user';

import serviceContext from 'infrastructure/service/context';
import ArrowBackButton from 'presentation/layout/arrow-back-button';
import Spinner from 'presentation/layout/spinner';

const FormCard = styled(Card)`
  min-height: calc(100vh - 300px);
`;

export default function UserShowContainer(): React.ReactElement {
  const { userService } = serviceContext;
  const navigate = useNavigate();
  const location = useLocation();

  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({} as User);

  function goBack(): void {
    if (location.state) {
      navigate(-1);
    } else {
      navigate('../');
    }
  }

  const getUser = useCallback(async () => {
    if (userId) {
      setLoading(true);
      const userOrError = await userService.getById(userId);
      if (userOrError.isRight()) {
        setUser(userOrError.value as User);
      }
      setLoading(false);
    }
  }, [userId, userService]);

  useEffect(() => {
    getUser();
  }, [userId, getUser]);

  const userFormProps = {
    user,
    deleteUser: (id: string): void => {
      console.log(id); // eslint-disable-line no-console
      setLoading(true);
      setTimeout(() => goBack(), 2000);
    },
    updateUser: (userToUpdate: User): void => {
      console.log(userToUpdate); // eslint-disable-line no-console
    },
  };

  return (
    <Container maxWidth="xl">
      <Grow in>
        <div>
          <Stack direction="row" alignItems="center" mb={2}>
            <Typography variant="h1" gutterBottom>
              <ArrowBackButton onClick={() => goBack()} />
              User: {user.name}
            </Typography>
          </Stack>
          <FormCard>
            {loading && <Spinner />}
            {!loading && <UserFormComponent {...userFormProps} />}
          </FormCard>
        </div>
      </Grow>
    </Container>
  );
}
