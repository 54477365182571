/* eslint-disable */
import React from 'react';
import { Grow, Container, Stack, Typography } from '@material-ui/core';

export default class DashboardContainer extends React.Component {

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Container>
        <Grow in>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h1" gutterBottom>
              Dashboard
            </Typography>
          </Stack>
        </Grow>
      </Container>
    );
  };
}
