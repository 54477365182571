import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { TableHeadProps } from './types';

export default function TableHeadComponent({
  columns,
  hasActions,
}: TableHeadProps): React.ReactElement {
  return (
    <TableHead>
      <TableRow>
        {columns.map((item, i) => (
          <TableCell key={`th-${i}`}>
            <strong style={{ whiteSpace: 'nowrap' }}>{item.label}</strong>
          </TableCell>
        ))}
        {hasActions && (
          <TableCell>
            <strong>Actions</strong>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
