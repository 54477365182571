/* eslint-disable @typescript-eslint/no-explicit-any */
export default class FormHelper {
  formData: Record<string, Record<string, any>>;

  constructor(formData: Record<string, Record<string, any>>) {
    this.formData = formData;
  }

  isValid(): boolean {
    let valid = true;
    Object.keys(this.formData).map((v) => {
      if (!this.formData[v].valid || !valid) {
        valid = false;
      }
      return null;
    });
    return valid;
  }

  parseForm(): Record<string, any> {
    return Object.keys(this.formData).reduce(
      (data, key) => ({ ...data, [key]: this.formData[key] }),
      {}
    );
  }
}
