import React, { useState, useMemo, useCallback } from 'react';
import Field from 'presentation/field';
import Submit from 'presentation/inputs/submit';
import ConfirmModal from 'presentation/modals/confirm';
import { Grid, Typography, Button, Stack, Badge } from '@material-ui/core';
import { Face, ChildCare, Android, Apple } from '@material-ui/icons/';
import styled from 'styled-components';
import FormHelper from 'infrastructure/helpers/form.helper';

import UserFields from './fields';
import PasswordDialog from './password-modal';
import { User, Profile, Device } from './types';

const FormBlock = styled(Grid)`
  padding: 0 1rem;
  margin-bottom: 1rem;
  border: 1px solid #e1e1e1;
  border-radius: 1rem;
  width: 100%;
`;

const FieldsWrapper: React.FC = ({ children }) => (
  <Stack direction="row" spacing={2} style={{ alignItems: 'center' }}>
    {children}
  </Stack>
);

const Footer = styled.footer`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid #e1e1e1;
  margin-top: 2rem;
  padding-top: 2rem;
`;

interface UserShowProps {
  user: User;
  deleteUser: (id: string) => void;
  updateUser: (user: User) => void;
}

export default function UserFormComponent({
  user,
  deleteUser,
  updateUser,
}: UserShowProps): React.ReactElement {
  const [formData, setFormData] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const formHelper = useMemo(() => new FormHelper(formData), [formData]);

  const updateForm = useCallback(
    (e: HTMLInputElement, valid?: boolean): void => {
      const field = Object();
      field[e.name] = {
        value: e.value,
        valid,
      };
      setFormData({ ...formData, ...field });
      setFormValid(formHelper.isValid());
    },
    [formData, formHelper]
  );

  const handleSubmitForm = useCallback((): void => {
    updateUser({ ...formData } as User);
  }, [formData, updateUser]);

  const { profiles, devices } = user;

  return (
    <Grid container>
      <FormBlock item xs={12}>
        <h3>
          <Typography>Account</Typography>
        </h3>
        <FieldsWrapper>
          {UserFields.account.map((field) => (
            <Field
              key={field.label}
              type={field.type}
              name={field.name}
              disabled={field.disabled}
              format={field.format}
              value={user[field.name] || field.value}
              label={field.label}
              validations={field.validations ? field.validations : undefined}
              handleUpdate={updateForm}
            />
          ))}
        </FieldsWrapper>
      </FormBlock>
      <FormBlock item xs={12}>
        <h3>
          <Typography>Subscription</Typography>
        </h3>
        <FieldsWrapper>
          {UserFields.subscription.map((field) => (
            <Field
              type={field.type}
              key={field.name}
              name={field.name}
              format={field.format}
              disabled={field.disabled}
              value={user[field.name] || field.value}
              label={field.label}
              handleUpdate={updateForm}
            />
          ))}
        </FieldsWrapper>
      </FormBlock>
      {profiles ? (
        <FormBlock>
          <h3>
            <Typography>Profiles</Typography>
          </h3>
          {profiles.map((profile: Profile) => (
            <FieldsWrapper key={`profile${profile.id}`}>
              <span>
                {profile.type === 'parent' ? (
                  <Face fontSize="large" color="primary" />
                ) : (
                  <ChildCare fontSize="large" color="secondary" />
                )}
              </span>
              {UserFields.profiles.map((profileField) => (
                <Field
                  key={profileField.name}
                  type={profileField.type}
                  name={profileField.name}
                  value={
                    profile[profileField.name] ? profile[profileField.name] : profileField.value
                  }
                  disabled
                  label={profileField.label}
                  handleUpdate={updateForm}
                />
              ))}
            </FieldsWrapper>
          ))}
        </FormBlock>
      ) : (
        ''
      )}
      {devices ? (
        <FormBlock>
          <h3>
            <Typography>Devices</Typography>
          </h3>
          {devices.map((device: Device) => (
            <FieldsWrapper key={`device${device.id}`}>
              <Badge
                style={{ margin: '0 0.5rem 1.3rem 0' }}
                badgeContent={device.version}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                color="info"
              >
                {device.platform === 'apple' ? (
                  <Apple fontSize="large" />
                ) : (
                  <Android fontSize="large" color="success" />
                )}
              </Badge>
              {UserFields.devices.map((deviceField) =>
                !deviceField.hide ? (
                  <Field
                    key={deviceField.name}
                    type={deviceField.type}
                    name={deviceField.name}
                    format={deviceField.format}
                    value={device[deviceField.name] ? device[deviceField.name] : deviceField.value}
                    disabled
                    label={deviceField.label}
                    handleUpdate={updateForm}
                  />
                ) : (
                  <></>
                )
              )}
            </FieldsWrapper>
          ))}
        </FormBlock>
      ) : (
        ''
      )}

      <Footer>
        <div>
          <PasswordDialog />
          &ensp;
          <Button variant="contained" color="secondary">
            E-mail password
          </Button>
        </div>
        <div>
          <ConfirmModal
            open={confirmDelete}
            confirm={() => deleteUser(user.id)}
            cancel={() => setConfirmDelete(false)}
            title="Confirm deletion"
            content={`Delete user "${user.name}"?`}
          >
            <Button variant="contained" color="error" onClick={() => setConfirmDelete(true)}>
              Delete User
            </Button>
          </ConfirmModal>
          &ensp;
          <Submit value="Save" handleSubmit={handleSubmitForm} isValid={formValid} />
        </div>
      </Footer>
    </Grid>
  );
}
