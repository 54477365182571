import { v4 as uuidv4 } from 'uuid';
import { useState, useEffect, useRef, useCallback } from 'react';
import { FormControl, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FieldType } from 'infrastructure/types/field';
import { TextInput, PasswordInput, SelectInput, SwitchInput } from '../inputs';

const ErrorList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0.5rem 0 0 0;
`;

type Validation = {
  pattern?: RegExp;
  function?: (v?: HTMLInputElement) => boolean;
  message: string;
};

const FieldComponent: React.FC<FieldType> = ({
  type,
  value,
  name,
  label,
  required,
  options,
  placeholder,
  validations,
  disabled,
  readonly,
  toggleEdit,
  dirty,
  hide,
  width,
  format,
  handleFocus,
  handleInput,
  handleChange,
  handleBlur,
  handleUpdate,
  mask,
}) => {
  const newInput = document.createElement('input');
  newInput.name = name;
  const [input, setInput] = useState<HTMLInputElement>(newInput);
  const [isValid, setIsValid] = useState(required ? `${value}`.length > 0 : true);
  const [isDirty, setIsDirty] = useState(false);
  const [isEmpty, setIsEmpty] = useState(!value);
  const [errors, setErrors] = useState<string[]>([]);

  const uuid = uuidv4();

  const validateField = useCallback(
    (e: HTMLInputElement): void => {
      const newErrors: string[] = [];
      setInput(e);
      if (validations) {
        validations.rules.map((item: Validation) => {
          let valid = false;
          if (item.pattern) {
            valid = item.pattern.test(input.value);
          } else if (item.function) {
            valid = item.function(e);
          }
          if (!valid) {
            newErrors.push(item.message);
          }
          return null;
        });
      }
      setIsEmpty(input.value.length === 0);
      setErrors(newErrors);
      setIsValid(errors.length === 0 && input.value.length > 0);
    },
    [setInput, input, errors, validations]
  );

  const onInput = useCallback(
    async (e: HTMLInputElement): Promise<void> => {
      await validateField(e);
      if (handleInput && input) {
        handleInput(input, isValid);
      }
    },
    [input, isValid, validateField, handleInput]
  );

  const onChange = useCallback(
    async (e: HTMLInputElement): Promise<void> => {
      await validateField(e);
      if (handleChange) {
        handleChange(e);
      }
    },
    [validateField, handleChange]
  );

  const onFocus = useCallback(
    (e: HTMLInputElement): void => {
      setIsDirty(false);
      if (handleFocus) {
        handleFocus(e);
      }
    },
    [setIsDirty, handleFocus]
  );

  const onBlur = useCallback(
    async (e: HTMLInputElement): Promise<void> => {
      setIsDirty(true);
      await validateField(e);
      if (handleBlur) {
        handleBlur(e);
      }
    },
    [validateField, handleBlur]
  );

  const onUpdate = useCallback((): void => {
    handleUpdate(input, isValid);
  }, [input, isValid, handleUpdate]);

  const prevValue = useRef(input);

  useEffect(() => {
    if (input.value !== prevValue.current.value) {
      onUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value, input, isValid]);

  useEffect(() => {
    if (dirty !== undefined) {
      setIsDirty(dirty);
    }
  }, [dirty]);

  function showErrors(): React.ReactFragment {
    if (errors.length > 0 && !isEmpty) {
      if (!isValid && isDirty) {
        return (
          <>
            {errors.map((item) => (
              <li className="field--error" key={item}>
                <Typography variant="caption" color="error">
                  {item}
                </Typography>
              </li>
            ))}
          </>
        );
      }
    }
    return '';
  }
  function showValidations(): React.ReactFragment {
    function getColor(pattern: RegExp): string {
      if (input.value) {
        return pattern.test(input.value) ? 'primary' : 'error';
      }
      return '';
    }
    if (validations) {
      return (
        <>
          {validations.rules.map((item: Validation) => {
            const valid =
              item.pattern && input.value.length > 0 ? item.pattern.test(input.value) : null;
            return (
              <li key={`validation${item.message}`}>
                <Typography variant="caption" color={item.pattern ? getColor(item.pattern) : ''}>
                  {item.pattern && valid ? '✅' : '❌'} {item.message}
                </Typography>
              </li>
            );
          })}
        </>
      );
    }
    return 'ss';
  }
  function renderInput(): React.ReactFragment {
    switch (type) {
      case 'text':
        return (
          <TextInput
            id={uuid}
            value={value}
            name={name}
            label={label}
            toggleEdit={toggleEdit}
            placeholder={placeholder}
            disabled={disabled}
            readonly={readonly}
            format={format}
            handleFocus={onFocus}
            handleInput={onInput}
            isValid={isValid}
            handleChange={onChange}
            handleBlur={onBlur}
            mask={mask}
          />
        );
      case 'password':
        return (
          <PasswordInput
            id={uuid}
            label={label}
            value={value}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            readonly={readonly}
            handleFocus={onFocus}
            handleInput={onInput}
            handleChange={onChange}
            handleBlur={onBlur}
          />
        );
      case 'select':
        return (
          <SelectInput
            id={uuid}
            value={value}
            name={name}
            label={label}
            options={options}
            placeholder={placeholder}
            disabled={disabled}
            readonly={readonly}
            handleFocus={onFocus}
            handleChange={onChange}
            handleBlur={onBlur}
          />
        );
      case 'switch':
        return (
          <SwitchInput
            id={uuid}
            value={value}
            name={name}
            label={label}
            disabled={disabled}
            handleFocus={onFocus}
            handleChange={onChange}
            handleBlur={onBlur}
          />
        );
      default:
        return <></>;
    }
  }
  function renderErrors(): React.ReactFragment {
    return (
      <ErrorList>
        {validations && validations.realtime ? showValidations() : showErrors()}
        {required && isDirty && isEmpty ? (
          <li>
            <Typography variant="caption" color="error">
              Campo obrigatório
            </Typography>
          </li>
        ) : (
          ''
        )}
      </ErrorList>
    );
  }
  return (
    <div style={{ width }}>
      <FormControl margin="normal" disabled={disabled} fullWidth>
        {!hide ? renderInput() : ''}
        {renderErrors()}
      </FormControl>
    </div>
  );
};

export default FieldComponent;
