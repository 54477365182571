import { Outlet } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Fade } from '@material-ui/core';
import Header from '../../presentation/layout/header';
import SideBar from '../../presentation/layout/sidebar';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')`
  display: flex;
  min-height: 100%;
`;
const MainStyle = styled(`div`)(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function Main(): React.ReactElement {
  return (
    <Fade in>
      <RootStyle>
        <Header />
        <SideBar />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </Fade>
  );
}
