import {
  PAGE_INITIAL_VALUE,
  ROWS_PER_PAGE_OPTIONS,
  SearchProps,
} from 'infrastructure/common/pageble';

export function extractPageableProps(queryParams: URLSearchParams): {
  page: number;
  rowsPerPage: number;
} {
  const pageParam = Number(queryParams.get('page'));
  const rowsPerPageParam = Number(queryParams.get('rowsPerPage'));
  return {
    page: Number.isInteger(pageParam) ? pageParam : PAGE_INITIAL_VALUE,
    rowsPerPage: ROWS_PER_PAGE_OPTIONS[rowsPerPageParam]
      ? rowsPerPageParam
      : ROWS_PER_PAGE_OPTIONS.FIVE,
  };
}

export function extractSearchProps(queryParams: URLSearchParams): SearchProps | null {
  const params = new URLSearchParams(queryParams.toString());
  params.delete('page');
  params.delete('rowsPerPage');

  const paramsArray = Array.from(params.entries());
  if (paramsArray.length) {
    return {
      key: paramsArray[0][0],
      value: paramsArray[0][1],
    } as SearchProps;
  }
  return null;
}

export function changePageParam(queryParams: URLSearchParams, page: number): URLSearchParams {
  const newQueryParams = new URLSearchParams(queryParams.toString());
  newQueryParams.delete('page');
  if (page) {
    newQueryParams.set('page', `${page}`);
  }
  return newQueryParams;
}

export function changeRowsPerPageParam(
  queryParams: URLSearchParams,
  rowsPerPage: number
): URLSearchParams {
  const newQueryParams = new URLSearchParams(queryParams.toString());
  newQueryParams.set('rowsPerPage', `${rowsPerPage}`);
  newQueryParams.delete('page');
  return newQueryParams;
}

export function addSearchParam(
  queryParams: URLSearchParams,
  field: string,
  term: string
): URLSearchParams {
  const newQueryParams = new URLSearchParams();
  if (field && term) {
    newQueryParams.set(field, term);
  }
  if (queryParams.get('rowsPerPage')) {
    newQueryParams.set('rowsPerPage', `${queryParams.get('rowsPerPage')}`);
  }
  return newQueryParams;
}

export function removeSearchParam(queryParams: URLSearchParams): URLSearchParams {
  const newQueryParams = new URLSearchParams();
  if (queryParams.get('page')) {
    newQueryParams.set('page', `${queryParams.get('page')}`);
  }
  if (queryParams.get('rowsPerPage')) {
    newQueryParams.set('rowsPerPage', `${queryParams.get('rowsPerPage')}`);
  }
  return newQueryParams;
}
