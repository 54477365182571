/* eslint-disable */
import { Either, left, right } from 'infrastructure/common/either';
import { Dispatch } from 'react';
import SecurityStorageHelper from 'infrastructure/helpers/security-storage.helper';
import { AuthActionType, AuthAction } from 'infrastructure/security/reducer';
import serviceContext from 'infrastructure/service/context';
import { Credentials } from './model';

const { authService } = serviceContext;

export interface AuthActions {
  login (data: Credentials): Promise<Either<Error, unknown>>;
  logout (): void;
}

export function useAuthActions(dispatch: Dispatch<AuthAction>): AuthActions {
  return {
    async login (data: Credentials): Promise<Either<Error, unknown>> {
      const authenticationOrError = await authService.login(data);
      if (authenticationOrError.isLeft()) {
        return left(Error());
      }
      
      const authentication = authenticationOrError.value;
      SecurityStorageHelper.setAuthentication(authentication);
      
      dispatch({ type: AuthActionType.LOGIN, payload: authentication });
      return right({});
    },
    logout (): void {
      SecurityStorageHelper.removeAuthentication();
      dispatch({ type: AuthActionType.LOGOUT });
    },
  } as AuthActions;
}




