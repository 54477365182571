/* eslint-disable */
import faker from 'faker';
import { Either, right } from 'infrastructure/common/either';
import { PageableRequest, PageableResponse } from 'infrastructure/common/pageble';
import { PlanDTO } from '../dto/plan.dto';
import { PlanService } from '../plan.service';

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export class PlanServiceMock implements PlanService {
  getAll = async ({
    filter,
    page,
    rowsPerPage,
  }: PageableRequest
  ): Promise<Either<Error, PageableResponse<PlanDTO>>> => {
    await delay(2000);
    await delay(2000);
    const response = { data: [] as PlanDTO[] };
    for (let i = 0; i < 32; i += 1) {
      response.data.push({
        id: `${i + 1}`,
        sku: faker.datatype.uuid(),
        name: faker.commerce.productName(),
        price: `R$${faker.commerce.price()}`,
        renewPeriod: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Biannual', 'Yearly'][
          faker.datatype.number(4)
        ],
        activeUsers: `${faker.datatype.number()}`,
        created: faker.date.past().toString(),
        businessPartner: ['vindi', 'google', 'apple', 'digible'][faker.datatype.number(3)],
      } as PlanDTO);
    }

    let rows = response.data;
    let total = response.data.length;

    if (filter && filter.search) {
      const filteredRows = rows.filter((it: any) => (
        it[filter.search.key] && 
        `${it[filter.search.key]}`.toLowerCase().indexOf(`${filter.search.value}`.toLowerCase()) > -1));
      rows = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      total = rows.length;
    }
    
    rows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const pageableResponse = { total, rowsPerPage, page, data: rows } as PageableResponse<PlanDTO>;
    return right(pageableResponse);
  };

  getById = async (id: string): Promise<Either<Error, PlanDTO>> => {
    await delay(1000);
    const plan = {
      id,
      sku: faker.datatype.uuid(),
      name: faker.commerce.productName() as string,
      price: `R$${faker.commerce.price()}`,
      renewPeriod: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Biannual', 'Yearly'][
        faker.datatype.number(4)
      ],
      activeUsers: `${faker.datatype.number()}`,
      created: faker.date.past().toString(),
      businessPartner: ['vindi', 'google', 'apple', 'digible'][faker.datatype.number(3)],
      mobileOperator: ['TIM', 'Vivo', 'Claro', 'Oi'][faker.datatype.number(3)],
      description: faker.commerce.productDescription(),
      renewPeriodCount: `${faker.datatype.number(4)}`,
      status: ['active', 'inactive'][faker.datatype.number(1)],
      isFree: faker.datatype.boolean(),
    } as PlanDTO;
    return right(plan);
  };
}

export const planServiceMock = new PlanServiceMock();
