/* eslint-disable */
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import { Box, List, ListItem, ListItemText, ListItemIcon, Divider, Typography } from "@material-ui/core";
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  Assignment as AssignmentIcon,
  Report as ReportIcon,
  Settings as SettingsIcon,
} from "@material-ui/icons";

const Wrapper = styled(`aside`)`
  width: 200px;
  height: 100vh;
  position: sticky;
  top: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 2;
`;
const LogoBox = styled(Box)`
  padding: 2rem;
  display: flex;
  justify-content: center;
`;
const Logo = styled(`img`)`
  width: 10rem;
`;

const routes = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    route: "/"
  },
  {
    name: "Users",
    icon: <PeopleIcon />,
    route: "/users"
  },
  {
    name: "Plans",
    icon: <AssignmentIcon />,
    route: "/plans"
  },
  {
    name: "Reports",
    icon: <ReportIcon />,
    route: "/reports"
  },
  {
    name: "Settings",
    icon: <SettingsIcon />,
    route: "/settings"
  },
]

export default function Sidebar(): React.ReactElement {

  return (
    <Wrapper data-testid="sidebar-wrapper">
      <LogoBox data-testid="logo-box">
        <Box data-testid="logo-box__link" component={RouterLink} to="/" style={{ textDecoration: 'none' }}>
          <Logo data-testid="logo-box__link__logo" alt="PlayKids Logo" src="/img/logo.png" />
          <Typography data-testid="logo-box__link__title" color="primary" align="center" marginTop="0" marginBottom="-24px">
            <small>Backoffice</small>
          </Typography>
        </Box>
      </LogoBox>
      <Box data-testid="list-box">
       <List data-testid="item-list">
         {routes.map((item, i)=>(
           <ListItem
            data-testid={`item-list__item-link-${i}`}
            button
            key={"route" + item.name}
            component={RouterLink}
            to={item.route}
           >
             <ListItemIcon data-testid={`item-list__item-link-${i}__icon`}>
               {item.icon}
             </ListItemIcon>
             <ListItemText data-testid={`item-list__item-link-${i}__title`} primary={item.name} />
           </ListItem>
         ))}
        </List>
        <Divider data-testid="divider" />
       </Box>
    </Wrapper>
  );
}
