import { SyntheticEvent, useState, useCallback } from 'react';
import { FormControl, InputLabel, NativeSelect, OutlinedInput } from '@material-ui/core/';
import type { SelectInputType } from '../../infrastructure/types/inputs';

export type AcceptedMaskType = IMask.AnyMaskedOptions['mask'];

const SelectInputComponent: React.FC<SelectInputType> = ({
  value,
  name,
  label,
  id,
  options,
  size,
  placeholder,
  handleFocus,
  handleChange,
  handleBlur,
}) => {
  const [selectValue, setSelectValue] = useState(value || '');
  const onChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>): void => {
      const target = e.target as HTMLInputElement;
      setSelectValue(target.value);
      if (handleChange) {
        handleChange(target);
      }
    },
    [handleChange]
  );

  const onFocus = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      if (handleFocus) {
        handleFocus(target);
      }
    },
    [handleFocus]
  );

  const onBlur = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      if (handleBlur) {
        handleBlur(target);
      }
    },
    [handleBlur]
  );

  return (
    <FormControl css={{ minWidth: 120 }} variant="outlined">
      <InputLabel
        id={id}
        style={{
          background: '#ffffff',
          borderLeft: '0.4rem solid white',
          borderRight: '0.4rem solid white',
          marginLeft: '-0.4rem',
        }}
      >
        {label}
      </InputLabel>
      <NativeSelect
        fullWidth
        name={name}
        id={id}
        input={<OutlinedInput />}
        size={size}
        value={selectValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <option value="" disabled>
          {placeholder || 'none'}
        </option>

        {options
          ? options.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <option key={`option${id}-${i}`} value={item.value}>
                {item.label}
              </option>
            ))
          : ''}
      </NativeSelect>
    </FormControl>
  );
};

export default SelectInputComponent;
