import { Either, left, right } from 'infrastructure/common/either';
import { Authentication, Credentials } from 'infrastructure/security/model';
import { AuthService } from '../auth.service';

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export class AuthServiceMock implements AuthService {
  login = async (data: Credentials): Promise<Either<Error, Authentication>> => {
    await delay(2000);
    if (!`${data.username}`.match(/playkids/)) {
      return left(Error('An user with this username and password cannot be found!'));
    }
    return right({
      token: '94744ffb-e6c5-439f-a905-e2bdf3ff4b53',
      principal: {
        username: data.username,
        name: 'Usuário logado',
        roles: ['USER_ADMIN'],
      },
    });
  };
}

export const authServiceMock = new AuthServiceMock();
