import { TextField, InputAdornment } from '@material-ui/core';
import { SyntheticEvent, useState, useEffect, useCallback } from 'react';
import { Clear, Search } from '@material-ui/icons';
import type { SearchInputType } from '../../infrastructure/types/inputs';

export type AcceptedMaskType = IMask.AnyMaskedOptions['mask'];

const SearchInputComponent: React.FC<SearchInputType> = ({
  value,
  name,
  placeholder,
  id,
  label,
  disabled,
  size,
  handleClear,
  handleClick,
  handleFocus,
  handleInput,
  handleChange,
  handleBlur,
}) => {
  const [textValue, setTextValue] = useState(`${value}`);

  useEffect(() => {
    if (value) {
      setTextValue(`${value}`);
    }
  }, [setTextValue, value]);

  const onClick = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      if (handleClick) {
        handleClick(target);
      }
    },
    [handleClick]
  );

  const onInput = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      setTextValue(target.value);
      if (handleInput) {
        handleInput(target);
      }
    },
    [handleInput]
  );

  const onFocus = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      if (handleFocus) {
        handleFocus(target);
      }
    },
    [handleFocus]
  );

  const onChange = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      if (handleChange) {
        handleChange(target);
      }
    },
    [handleChange]
  );

  const onBlur = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      if (handleBlur) {
        handleBlur(target);
      }
    },
    [handleBlur]
  );

  const onClear = useCallback((): void => {
    setTextValue('');
    handleClear();
  }, [handleClear]);

  return (
    <TextField
      id={id}
      type="text"
      name={name}
      autoComplete="off"
      size={size || 'small'}
      label={label}
      value={textValue}
      placeholder={placeholder}
      disabled={disabled}
      InputProps={{
        name,
        id,
        endAdornment: (
          <InputAdornment position="end">
            {textValue.length > 0 ? (
              <Clear onClick={() => onClear()} style={{ cursor: 'pointer' }} />
            ) : (
              <Search color="disabled" />
            )}
          </InputAdornment>
        ),
      }}
      onClick={onClick}
      onFocus={onFocus}
      onInput={onInput}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default SearchInputComponent;
