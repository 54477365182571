import { SyntheticEvent, useState, useCallback } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { IMaskInput } from 'react-imask';
import IMask from 'imask';
import { Edit, Cancel, CheckCircle } from '@material-ui/icons';
import type { TextInputType } from '../../infrastructure/types/inputs';

export type AcceptedMaskType = IMask.AnyMaskedOptions['mask'];

const TextInputComponent: React.FC<TextInputType> = ({
  value,
  name,
  placeholder,
  id,
  maxLength,
  label,
  disabled,
  readonly,
  toggleEdit,
  format,
  handleClick,
  handleFocus,
  handleInput,
  handleChange,
  handleBlur,
  mask,
  isValid,
}) => {
  const [textValue, setTextValue] = useState(format ? format(`${value}` || '') : value || '');
  const [focused, setFocused] = useState(false);
  const [edit, setEdit] = useState(readonly || disabled);

  const onClick = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      if (handleClick) {
        handleClick(target);
      }
    },
    [handleClick]
  );

  const onInput = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      setTextValue(target.value);
      if (handleInput) {
        handleInput(target);
      }
    },
    [setTextValue, handleInput]
  );

  const onFocus = useCallback(
    (e: SyntheticEvent): void => {
      setFocused(true);
      const target = e.target as HTMLInputElement;
      if (handleFocus) {
        handleFocus(target);
      }
    },
    [setFocused, handleFocus]
  );

  const onChange = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      if (handleChange) {
        handleChange(target);
      }
    },
    [handleChange]
  );

  const onBlur = useCallback(
    (e: SyntheticEvent): void => {
      setFocused(false);
      const target = e.target as HTMLInputElement;
      if (handleBlur) {
        handleBlur(target);
      }
    },
    [setFocused, handleBlur]
  );

  const renderEndAdornment: () => React.ReactNode = () => {
    if (toggleEdit) {
      return (
        <InputAdornment position="end">
          {edit ? (
            <Edit onClick={() => setEdit(!edit)} color="primary" />
          ) : (
            <>
              <Cancel
                onClick={() => {
                  setEdit(!edit);
                  if (value) {
                    if (format) {
                      setTextValue(format(`${value}`));
                    } else {
                      setTextValue(value);
                    }
                  }
                }}
                color="warning"
              />
              <CheckCircle color="success" />
            </>
          )}
        </InputAdornment>
      );
    }
    return <></>;
  };

  if (mask) {
    return (
      <IMaskInput
        id={id}
        type="text"
        value={`${textValue}`}
        radix="."
        maxLength={maxLength}
        unmask
        name={name}
        disabled={disabled}
        readOnly={readonly}
        lazy={false}
        inputRef={() => null}
        placeholder={placeholder}
        onFocus={() => onFocus}
        onInput={onInput}
        onAccept={() => onChange}
        onBlur={onBlur}
        mask={mask}
      />
    );
  }

  return (
    <TextField
      fullWidth
      id={id}
      autoComplete="off"
      type="text"
      name={name}
      label={label}
      value={textValue}
      error={!isValid && !focused && `${textValue}`.length > 0}
      placeholder={placeholder}
      disabled={edit}
      InputProps={{
        endAdornment: renderEndAdornment(),
      }}
      onClick={onClick}
      onFocus={onFocus}
      onInput={onInput}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default TextInputComponent;
