import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Stack, Typography, Card, Grow } from '@material-ui/core';
import { User, UserListComponent } from 'domain/user';
import {
  PageableRequest,
  PageableResponse,
  FilterProps,
  TOTAL_INITIAL_VALUE,
} from 'infrastructure/common/pageble';
import {
  addSearchParam,
  changePageParam,
  changeRowsPerPageParam,
  extractPageableProps,
  extractSearchProps,
  removeSearchParam,
} from 'infrastructure/helpers/url-params.helper';
import { UserDTO } from 'infrastructure/service/dto/user.dto';
import serviceContext from 'infrastructure/service/context';

const { userService } = serviceContext;

export default function UserListContainer(): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState(new URLSearchParams(location.search));

  const [loading, setLoading] = useState(true);
  const [pageable, setPageable] = useState({
    ...extractPageableProps(queryParams),
    total: TOTAL_INITIAL_VALUE,
    data: [],
  } as PageableResponse<User>);

  const getUserList = useCallback(
    async (page: number, rowsPerPage: number, filterProps: FilterProps) => {
      setLoading(true);
      const pageableResponseOrError = await userService.getAll({
        page,
        rowsPerPage,
        filter: filterProps,
      } as PageableRequest);

      if (pageableResponseOrError.isRight()) {
        const pageableResponse = pageableResponseOrError.value;
        setPageable({
          ...pageableResponse,
          data: pageableResponse.data.map((it: UserDTO) => ({ ...it } as User)),
        } as PageableResponse<User>);
      }
      setLoading(false);
    },
    []
  );

  function updateQueryParams(newQueryParams: URLSearchParams): void {
    const { page, rowsPerPage } = extractPageableProps(newQueryParams);
    setPageable({
      ...pageable,
      page,
      rowsPerPage,
    } as PageableResponse<User>);
    setQueryParams(newQueryParams);
  }

  useEffect(() => {
    const { page, rowsPerPage } = extractPageableProps(queryParams);
    const filterProps = { search: extractSearchProps(queryParams) } as FilterProps;
    getUserList(page, rowsPerPage, filterProps);
  }, [queryParams, getUserList]);

  const userListProps = {
    loading,
    search: extractSearchProps(queryParams),
    pageable,
    showUser: (id: number) => {
      navigate(`/users/${id}`, { state: { back: true } });
    },
    deleteUser: (id: number) => {
      alert(`The user: ${id} will be removed!`); // eslint-disable-line no-alert
    },
    searchUser: (field: string, term: string) => {
      const newQueryParams = addSearchParam(queryParams, field, term);
      updateQueryParams(newQueryParams);
      navigate(`./?${newQueryParams}`);
    },
    clearSearch: () => {
      const searchProps = extractSearchProps(queryParams);
      if (searchProps) {
        const newQueryParams = removeSearchParam(queryParams);
        updateQueryParams(newQueryParams);
        navigate(`./?${newQueryParams}`);
      }
    },
    onPageChange: (newPage: number) => {
      const newQueryParams = changePageParam(queryParams, newPage);
      updateQueryParams(newQueryParams);
      navigate(`./?${newQueryParams}`);
    },
    onRowsPerPageChange: (value: number) => {
      const newQueryParams = changeRowsPerPageParam(queryParams, value);
      updateQueryParams(newQueryParams);
      navigate(`./?${newQueryParams}`);
    },
  };

  return (
    <Container maxWidth="xl">
      <Grow in>
        <div>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h1" gutterBottom>
              Users
            </Typography>
          </Stack>
          <Card>
            <UserListComponent {...userListProps} />
          </Card>
        </div>
      </Grow>
    </Container>
  );
}
