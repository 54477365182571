import { Fade, TableBody, TableRow, TableCell, IconButton, Skeleton } from '@material-ui/core';
import { ROWS_PER_PAGE_OPTIONS } from 'infrastructure/common/pageble';
import { TableBodyProps } from 'presentation/table/types';

export default function TableBodyComponent({
  loading,
  columns,
  body,
  actions,
}: TableBodyProps): React.ReactElement {
  const columnsMap = columns.reduce((map, it) => {
    map.set(it.name, it.format);
    return map;
  }, new Map<string, ((value: unknown) => void) | undefined>());

  function renderCells(index: number, item: any): React.ReactElement {
    return (
      <>
        {Array.from(columnsMap.entries()).map(([key, format], z) => (
          <TableCell key={`td-${index}-${z}`}>{format ? format(item[key]) : item[key]}</TableCell>
        ))}
      </>
    );
  }

  function renderSkeleton(): React.ReactElement[] {
    return Array(ROWS_PER_PAGE_OPTIONS.FIVE)
      .fill(0)
      .map((it, i) => (
        <TableRow key={`tr-sk-${i}`}>
          {columns.map((col, x) => (
            <TableCell key={`th-sk-${i}-${x}`}>
              <Skeleton height="2rem" />
            </TableCell>
          ))}
          {actions ? (
            <TableCell key={`th-sk-${i}-ac`}>
              <Skeleton height="2rem" />
            </TableCell>
          ) : null}
        </TableRow>
      ));
  }
  return (
    <>
      {loading && (
        <Fade in>
          <TableBody>{renderSkeleton()}</TableBody>
        </Fade>
      )}
      {!loading && (
        <Fade in>
          <TableBody>
            {body.length ? (
              body.map((item, i) => (
                <TableRow key={`tr-${i}`} hover>
                  {renderCells(i, item)}
                  {actions && (
                    <TableCell>
                      <span style={{ display: 'flex' }}>
                        {actions.map((it) => (
                          <IconButton
                            key={`action${it.label}${i}`}
                            onClick={() => it.handler(item)}
                          >
                            {it.icon}
                          </IconButton>
                        ))}
                      </span>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + (actions ? 1 : 0)}>
                  <h1>Nenhum resultado</h1>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Fade>
      )}
    </>
  );
}
