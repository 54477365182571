import { AuthServiceMock } from './mock/auth.service.mock';
import { UserServiceMock } from './mock/user.service.mock';
import { PlanServiceMock } from './mock/plan.service.mock';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { PlanService } from './plan.service';

export class ServiceContext {
  readonly authService: AuthService;

  readonly userService: UserService;

  readonly planService: PlanService;

  constructor() {
    this.authService = new AuthServiceMock();
    this.userService = new UserServiceMock();
    this.planService = new PlanServiceMock();
  }
}

const serviceContext = new ServiceContext();
export default serviceContext;
