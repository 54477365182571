import { useState } from 'react';
import { Switch, FormControlLabel } from '@material-ui/core/';
import { InputType } from 'infrastructure/types/inputs';

const SwitchInput: React.FC<InputType> = ({ label, value }) => {
  const [checked, setChecked] = useState(!!value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
  };

  return (
    <FormControlLabel
      value="top"
      control={
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      }
      label={label}
    />
  );
};

export default SwitchInput;
