import { TableContainer, Table, Stack } from '@material-ui/core';
import { Create, DeleteForever } from '@material-ui/icons';
import {
  TableSearchComponent,
  TableHeadComponent,
  TableBodyComponent,
  TablePaginationComponent,
} from 'presentation/table';
import {
  TableHeadProps,
  TableBodyProps,
  TablePaginationProps,
  TableSearchProps,
} from 'presentation/table/types';
import { PageableResponse, SearchProps, MIN_LENGTH_TO_SEARCH } from 'infrastructure/common/pageble';
import { Plan } from './types';

const columns = [
  {
    label: 'UUID',
    name: 'sku',
    format(value: string) {
      return <small>{value}</small>;
    },
  },
  { label: 'Name', name: 'name' },
  { label: 'Price', name: 'price' },
  { label: 'Renew Period', name: 'renewPeriod' },
  { label: 'Active users', name: 'activeUsers' },
  {
    label: 'Created at',
    name: 'created',
    format(value: string) {
      return new Date(value).toLocaleDateString('pt-BR');
    },
  },
  { label: 'BP', name: 'businessPartner' },
];

interface PlanListProps {
  loading: boolean;
  search?: SearchProps | null;
  pageable: PageableResponse<Plan>;
  showPlan: (id: number) => void;
  deletePlan: (id: number) => void;
  searchPlan?: (field: string, term: string) => void;
  clearSearch?: () => void;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (value: number) => void;
}

export default function PlanListComponent({
  loading,
  search = null,
  pageable,
  showPlan,
  deletePlan,
  searchPlan,
  clearSearch,
  onPageChange,
  onRowsPerPageChange,
}: PlanListProps): React.ReactElement {
  const searchProps = {
    term: search?.value,
    field: search?.key,
    fields: [
      { label: 'name', field: 'name' },
      { label: 'sku', field: 'sku' },
    ],
    onSubmitSearch: searchPlan,
    onClearSearch: clearSearch,
    minLength: MIN_LENGTH_TO_SEARCH,
  } as TableSearchProps;

  const headProps = {
    columns,
    hasActions: true,
  } as TableHeadProps;

  const bodyProps = {
    loading,
    body: pageable.data,
    columns,
    actions: [
      {
        icon: <Create color="primary" />,
        label: 'edit',
        handler: (item: Plan) => {
          showPlan(Number(item.id));
        },
      },
      {
        icon: <DeleteForever color="warning" />,
        label: 'delete',
        handler: (item: Plan) => {
          deletePlan(Number(item.id));
        },
      },
    ],
  } as TableBodyProps;

  const paginationProps = {
    page: pageable.page,
    total: pageable.total,
    rowsPerPage: pageable.rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
  } as TablePaginationProps;

  return (
    <TableContainer sx={{ minWidth: 800 }}>
      <Stack direction="row" mt={0.5}>
        <TableSearchComponent {...searchProps} />
      </Stack>
      <Table>
        <TableHeadComponent {...headProps} />
        <TableBodyComponent {...bodyProps} />
        <TablePaginationComponent {...paginationProps} />
      </Table>
    </TableContainer>
  );
}
