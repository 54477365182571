import { Button, TableContainer, Table, Stack } from '@material-ui/core';
import { Create, DeleteForever } from '@material-ui/icons';
import {
  TableSearchComponent,
  TableHeadComponent,
  TableBodyComponent,
  TablePaginationComponent,
} from 'presentation/table';
import {
  TableHeadProps,
  TableBodyProps,
  TablePaginationProps,
  TableSearchProps,
} from 'presentation/table/types';
import { PageableResponse, SearchProps, MIN_LENGTH_TO_SEARCH } from 'infrastructure/common/pageble';
import { User } from './types';

const columns = [
  { label: 'Name', name: 'name', alignRight: false },
  { label: 'E-mail', name: 'email', alignRight: false },
  { label: 'Partner', name: 'partner', alignRight: false },
  {
    label: 'Status',
    name: 'status',
    alignRight: false,
    format(value: any) {
      return (
        <Button size="small" variant="contained" color="primary">
          {value}
        </Button>
      );
    },
  },
  {
    label: 'Created at',
    name: 'createdAt',
    alignRight: false,
    format(value: any) {
      return new Date(value).toLocaleDateString('pt-BR');
    },
  },
];

interface UserListProps {
  loading: boolean;
  search?: SearchProps | null;
  pageable: PageableResponse<User>;
  showUser: (id: number) => void;
  deleteUser: (id: number) => void;
  searchUser?: (field: string, term: string) => void;
  clearSearch?: () => void;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (value: number) => void;
}

export default function UserListComponent({
  loading,
  search = null,
  pageable,
  showUser,
  deleteUser,
  searchUser,
  clearSearch,
  onPageChange,
  onRowsPerPageChange,
}: UserListProps): React.ReactElement {
  const searchProps = {
    term: search?.value,
    field: search?.key,
    fields: [
      { label: 'name', field: 'name' },
      { label: 'ID', field: 'id' },
      { label: 'Phone', field: 'phone' },
      { label: 'SKY code', field: 'sky_code' },
    ],
    onSubmitSearch: searchUser,
    onClearSearch: clearSearch,
    minLength: MIN_LENGTH_TO_SEARCH,
  } as TableSearchProps;

  const headProps = {
    columns,
    hasActions: true,
  } as TableHeadProps;

  const bodyProps = {
    loading,
    body: pageable.data,
    columns,
    actions: [
      {
        icon: <Create color="primary" />,
        label: 'edit',
        handler: (item: User) => {
          showUser(Number(item.id));
        },
      },
      {
        icon: <DeleteForever color="warning" />,
        label: 'delete',
        handler: (item: User) => {
          deleteUser(Number(item.id));
        },
      },
    ],
  } as TableBodyProps;

  const paginationProps = {
    page: pageable.page,
    total: pageable.total,
    rowsPerPage: pageable.rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
  } as TablePaginationProps;

  return (
    <TableContainer sx={{ minWidth: 800 }}>
      <Stack direction="row" mt={0.5}>
        <TableSearchComponent {...searchProps} />
      </Stack>
      <Table>
        <TableHeadComponent {...headProps} />
        <TableBodyComponent {...bodyProps} />
        <TablePaginationComponent {...paginationProps} />
      </Table>
    </TableContainer>
  );
}
