import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Container, Card, Typography, Fade } from '@material-ui/core';
import { useAuthDispatch, useAuthActions, Credentials } from 'infrastructure/security';

import Alert from 'presentation/modals/alert';
import LoginForm from 'domain/authentication/login';

const CenterY = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;
const Logo = styled('img')`
  max-width: 100%;
`;

export default function LoginContainer(): React.ReactElement {
  const authActions = useAuthActions(useAuthDispatch());
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const closeAlert = useCallback((): void => {
    setLoading(false);
    setShowAlert(false);
  }, []);

  const doLogin = useCallback(
    async (credentials: Credentials): Promise<void> => {
      setLoading(true);
      const authenticationOrError = await authActions.login(credentials);
      if (authenticationOrError.isLeft()) {
        setShowAlert(true);
        return;
      }
      navigate('./');
    },
    [authActions, navigate]
  );

  return (
    <Fade in>
      <Container maxWidth="xs">
        <CenterY>
          <Typography variant="h5" color="primary" align="center" marginBottom="1rem">
            <Logo alt="PK logo" src="img/logo.png" /> Backoffice
          </Typography>
          <Card>
            <Alert
              open={showAlert}
              title="Error"
              content="Authentication error (tip: use playkids domain)"
              onClose={closeAlert}
            />
            <LoginForm loading={loading} doLogin={doLogin} />
          </Card>
        </CenterY>
      </Container>
    </Fade>
  );
}
