export interface SearchProps {
  key: string;
  value: string;
}

export interface FilterProps {
  search: SearchProps;
}

export interface PageableRequest {
  page: number;
  rowsPerPage: number;
  filter: FilterProps;
}

export interface PageableResponse<T> {
  total: number;
  page: number;
  rowsPerPage: number;
  data: T[];
}

export const PAGE_INITIAL_VALUE = 0;
export const MIN_LENGTH_TO_SEARCH = 3;
export const TOTAL_INITIAL_VALUE = 0;

export enum ROWS_PER_PAGE_OPTIONS {
  FIVE = 5,
  TEN = 10,
  TWENTY = 20,
  FIFTY = 50,
}

export function extractRowsPerPageOptionsValue(): number[] {
  return Object.keys(ROWS_PER_PAGE_OPTIONS)
    .map((it) => Number(it))
    .filter((it) => !Number.isNaN(it));
}
