import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { styled } from '@material-ui/core/styles';

const ArrowBackCustom = styled(ArrowBackIcon)`
  font-size: 1.6em;
`;

interface ArrowBackButtonProps {
  onClick: () => void;
}

export default function ArrowBackButton({ onClick }: ArrowBackButtonProps): React.ReactElement {
  return (
    <IconButton data-testid="arrow-back-button" color="secondary" onClick={onClick}>
      <ArrowBackCustom data-testid="arrow-back-icon" color="primary" />
    </IconButton>
  );
}
