// import Validations from 'infrastructure/resources/validations';

export default [
  {
    name: 'sku',
    type: 'text',
    label: 'sku',
    required: true,
    disabled: true,
    hide: true,
    value: '' as string | boolean,
    placeholder: 'sku',
  },
  {
    name: 'isFree',
    type: 'switch',
    label: 'Free plan',
    hide: true,
    required: true,
    disabled: false,
    value: '' as string | boolean,
    placeholder: 'isFree',
  },
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    width: '400px',
    required: true,
    disabled: false,
    value: '' as string | boolean,
    placeholder: 'name',
  },
  {
    name: 'status',
    type: 'select',
    label: 'Status',
    required: true,
    disabled: false,
    value: '' as string | boolean,
    placeholder: 'status',
    options: [
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Inactive',
        value: 'inactive',
      },
    ],
  },
  {
    name: 'businessPartner',
    type: 'select',
    label: 'BP',
    required: true,
    disabled: false,
    value: '' as string | boolean,
    placeholder: 'businessPartner',
    options: [
      {
        label: 'Vindi',
        value: 'vindi',
      },
      {
        label: 'Digible',
        value: 'digible',
      },
      {
        label: 'Google',
        value: 'google',
      },
      {
        label: 'Apple',
        value: 'apple',
      },
    ],
  },
  {
    name: 'mobileOperator',
    type: 'select',
    hide: true,
    label: 'Mobile operator',
    disabled: false,
    value: '' as string | boolean,
    width: '200px',
    options: [
      {
        label: 'TIM',
        value: 'tim',
      },
      {
        label: 'Vivo',
        value: 'vivo',
      },
      {
        label: 'Claro',
        value: 'claro',
      },
      {
        label: 'Oi',
        value: 'oi',
      },
    ],
  },

  {
    name: 'price',
    type: 'text',
    label: 'Currency/Price',
    required: true,
    disabled: false,
    value: '' as string | boolean,
    placeholder: 'price',
  },
  {
    name: 'Description',
    type: 'text',
    label: 'Description',
    required: true,
    disabled: false,
    value: '' as string | boolean,
    placeholder: 'Description',
  },
  {
    name: 'renewPeriod',
    type: 'select',
    label: 'Renew period',
    required: true,
    disabled: false,
    value: '' as string | boolean,
    placeholder: 'renewPeriod',
    options: [
      {
        value: 'daily',
        label: 'Daily',
      },
      {
        value: 'weekly',
        label: 'Weekly',
      },
      {
        value: 'monthly',
        label: 'Monthly',
      },
      {
        value: 'quarterly',
        label: 'Quarterly',
      },
      {
        value: 'biannual',
        label: 'Biannual',
      },
      {
        value: 'yearly',
        label: 'Yearly',
      },
    ],
  },
  {
    name: 'renewPeriodCount',
    type: 'text',
    label: 'Renew period count',
    required: true,
    disabled: false,
    value: '' as string | boolean,
    placeholder: 'renewPeriodCount',
  },
  {
    name: 'activeUsers',
    type: 'text',
    label: 'activeUsers',
    required: true,
    disabled: false,
    hide: true,
    value: '' as string | boolean,
    placeholder: 'activeUsers',
  },

  // {
  //   name: 'created',
  //   type: 'text',
  //   label: 'created',
  //   required: true,
  //   disabled: false,
  //   value: '' as string | boolean,
  //   placeholder: 'created',
  //   format: (v: string): string => new Date(v).toLocaleDateString('pt-BR'),
  // },
];
