// import React, { useState } from 'react';
// import Field from 'presentation/field';
// import Submit from 'presentation/inputs/submit';
// import ConfirmModal from 'presentation/modals/confirm';
// import { useNavigate } from 'react-router-dom';
// import { Grid, Typography, Button, Stack, Badge } from '@material-ui/core';
// import { Face, ChildCare, Android, Apple } from '@material-ui/icons/';
// import styled from 'styled-components';
// import FormHelper from 'infrastructure/helpers/form.helper';

// import PlanFields from './fields';
// import { Plan } from './types';

// const FormBlock = styled(Grid)`
//   padding: 0 1rem;
//   margin-bottom: 1rem;
//   border: 1px solid #e1e1e1;
//   border-radius: 1rem;
//   width: 100%;
// `;

// const FieldsWrapper: React.FC = ({ children }) => (
//   <Stack direction="row" spacing={2} style={{ alignItems: 'center' }}>
//     {children}
//   </Stack>
// );

// const Footer = styled.footer`
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
//   border-top: 1px solid #e1e1e1;
//   margin-top: 2rem;
//   padding-top: 2rem;
// `;

// export default function UserFormComponent({ user }: { user: User }): React.ReactElement {
//   const [formData, setFormData] = useState({});
//   const [formValid, setFormValid] = useState(false);
//   const [confirmDelete, setConfirmDelete] = useState(false);
//   const Navigate = useNavigate();
//   const formHelper = new FormHelper(formData);
//   function updateForm(e: HTMLInputElement, valid?: boolean): void {
//     const field = Object();
//     field[e.name] = {
//       value: e.value,
//       valid,
//     };
//     setFormData({ ...formData, ...field });
//     setFormValid(formHelper.isValid());
//   }

//   function handleClose(v: boolean): void {
//     if (v) {
//       Navigate('../');
//     }
//     setConfirmDelete(false);
//   }

//   const { profiles, devices } = user;

//   return (
//     <Grid container>
//       <FormBlock item xs={12}>
//         <h3>
//           <Typography>Account</Typography>
//         </h3>
//         <FieldsWrapper>
//           {UserFields.account.map((field) =>
//             Object.prototype.hasOwnProperty.call(user, field.name) ? (
//               <Field
//                 key={field.label}
//                 type={field.type}
//                 name={field.name}
//                 disabled={field.disabled}
//                 format={field.format}
//                 value={user[field.name] || field.value}
//                 label={field.label}
//                 validations={field.validations ? field.validations : undefined}
//                 handleUpdate={updateForm}
//               />
//             ) : (
//               ''
//             )
//           )}
//         </FieldsWrapper>
//       </FormBlock>
//       <FormBlock item xs={12}>
//         <h3>
//           <Typography>Subscription</Typography>
//         </h3>
//         <FieldsWrapper>
//           {UserFields.subscription.map((field) => (
//             <Field
//               type={field.type}
//               key={field.name}
//               name={field.name}
//               format={field.format}
//               disabled={field.disabled}
//               value={user[field.name] || field.value}
//               label={field.label}
//               handleUpdate={updateForm}
//             />
//           ))}
//         </FieldsWrapper>
//       </FormBlock>
//       {profiles ? (
//         <FormBlock>
//           <h3>
//             <Typography>Profiles</Typography>
//           </h3>
//           {profiles.map((profile: Profile) => (
//             <FieldsWrapper key={`profile${profile.id}`}>
//               <span>
//                 {profile.type === 'parent' ? (
//                   <Face fontSize="large" color="primary" />
//                 ) : (
//                   <ChildCare fontSize="large" color="secondary" />
//                 )}
//               </span>
//               {UserFields.profiles.map((profileField) => (
//                 <Field
//                   key={profileField.name}
//                   type={profileField.type}
//                   name={profileField.name}
//                   value={
//                     profile[profileField.name] ? profile[profileField.name] : profileField.value
//                   }
//                   disabled
//                   label={profileField.label}
//                   handleUpdate={updateForm}
//                 />
//               ))}
//             </FieldsWrapper>
//           ))}
//         </FormBlock>
//       ) : (
//         ''
//       )}
//       {devices ? (
//         <FormBlock>
//           <h3>
//             <Typography>Devices</Typography>
//           </h3>
//           {devices.map((device: Device) => (
//             <FieldsWrapper key={`device${device.id}`}>
//               <Badge
//                 style={{ margin: '0 0.5rem 1.3rem 0' }}
//                 badgeContent={device.version}
//                 anchorOrigin={{
//                   vertical: 'bottom',
//                   horizontal: 'right',
//                 }}
//                 color="info"
//               >
//                 {device.platform === 'apple' ? (
//                   <Apple fontSize="large" />
//                 ) : (
//                   <Android fontSize="large" color="success" />
//                 )}
//               </Badge>
//               {UserFields.devices.map((deviceField) =>
//                 !deviceField.hide ? (
//                   <Field
//                     key={deviceField.name}
//                     type={deviceField.type}
//                     name={deviceField.name}
//                     format={deviceField.format}
//                     value={device[deviceField.name] ? device[deviceField.name] : deviceField.value}
//                     disabled
//                     label={deviceField.label}
//                     handleUpdate={updateForm}
//                   />
//                 ) : (
//                   <></>
//                 )
//               )}
//             </FieldsWrapper>
//           ))}
//         </FormBlock>
//       ) : (
//         ''
//       )}

//       <Footer>
//         <div>
//           <PasswordDialog />
//           &ensp;
//           <Button variant="contained" color="secondary">
//             E-mail password
//           </Button>
//         </div>
//         <div>
//           <ConfirmModal
//             onClose={handleClose}
//             open={confirmDelete}
//             title="Confirm deletion"
//             content={`Delete user "${user.name}"?`}
//           >
//             <Button variant="contained" color="error" onClick={() => setConfirmDelete(true)}>
//               Delete User
//             </Button>
//           </ConfirmModal>
//           &ensp;
//           <Submit value="Save" handleSubmit={() => null} isValid={formValid} />
//         </div>
//       </Footer>
//     </Grid>
//   );
// }

/* eslint-disable */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Grid, Button, Stack, Typography } from '@material-ui/core';
import Field from 'presentation/field';
import Submit from 'presentation/inputs/submit';
import ConfirmModal from 'presentation/modals/confirm';
import { Plan } from './types';
import FormHelper from 'infrastructure/helpers/form.helper';

import fields from './fields';

const FormBlock = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

const FieldsWrapper: React.FC = ({ children }) => (
  <Stack direction="row" spacing={3}>
    {children}
  </Stack>
);

const Footer = styled.footer`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  border-top: 1px solid #e1e1e1;
  margin-top: 2rem;
  padding-top: 2rem;
`;

export default function PlanForm({ plan }: { plan: Plan }): React.ReactElement {
  const [formData, setFormData] = useState(Object());
  const [formValid, setFormValid] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const Navigate = useNavigate();
  const formHelper = new FormHelper(formData);
  const PlanFields = fields.map((field) => {
    const obj = field;
    obj.value = plan[field.name];
    if (field.name === 'mobileOperator') {
      obj.hide = plan.businessPartner !== 'digible';
    }
    return obj;
  });

  function updateForm(e: HTMLInputElement, valid?: boolean): void {
    const field = Object();
    field[e.name] = {
      value: e.value,
      valid,
    };
    setFormData({ ...formData, ...field });
    setFormValid(formHelper.isValid());
  }
  function handleClose(v: boolean): void {
    if (v) {
      Navigate('../');
    }
    setConfirmDelete(false);
  }
  function isHidden(field: Record<string, unknown>): boolean {
    if (field.name === 'mobileOperator' && formData.businessPartner) {
      return formData.businessPartner.value !== 'digible';
    }
    return field.hide === true;
  }

  return (
    <Grid container>
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginTop="-1rem"
        marginBottom="1rem"
      >
        <Typography>
          Sku: <strong>{plan.sku}</strong>
        </Typography>
        <Typography>
          Active users: <strong>{plan.activeUsers}</strong>
        </Typography>
        <Field
          key={PlanFields[1].label}
          type={PlanFields[1].type}
          name={PlanFields[1].name}
          required={PlanFields[1].required}
          disabled={PlanFields[1].disabled}
          options={PlanFields[1].options}
          value={PlanFields[1].value}
          label={PlanFields[1].label}
          handleUpdate={updateForm}
        />
      </Stack>
      <FormBlock>
        <FieldsWrapper>
          {PlanFields.map((field, index) =>
            Object.prototype.hasOwnProperty.call(plan, field.name) &&
            !isHidden(field) &&
            index <= 5 ? (
              <Field
                width={field.width}
                key={field.label}
                type={field.type}
                name={field.name}
                required={field.required}
                disabled={field.disabled}
                options={field.options}
                value={field.value}
                label={field.label}
                handleUpdate={updateForm}
              />
            ) : (
              ''
            )
          )}
        </FieldsWrapper>
        <FieldsWrapper>
          {PlanFields.map((field, index) =>
            Object.prototype.hasOwnProperty.call(plan, field.name) &&
            !isHidden(field) &&
            index > 5 ? (
              <Field
                width={field.width}
                key={field.label}
                type={field.type}
                name={field.name}
                required={field.required}
                disabled={field.disabled}
                options={field.options}
                value={field.value}
                label={field.label}
                handleUpdate={updateForm}
              />
            ) : (
              ''
            )
          )}
        </FieldsWrapper>
      </FormBlock>

      <Footer>
        <div>
          {/* <ConfirmModal
            onClose={handleClose}
            open={confirmDelete}
            title="Confirm deletion"
            content={`Delete plan "${plan.name}"?`}
          >
            <Button variant="contained" color="error" onClick={() => setConfirmDelete(true)}>
              Delete plan
            </Button>
          </ConfirmModal> */}
          &ensp;
          <Submit value="Save" handleSubmit={() => null} isValid={formValid} />
        </div>
      </Footer>
    </Grid>
  );
}
