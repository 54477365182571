import { CircularProgress } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const Wrapper = styled('div')`
  position: absolute;
  width: 100px;
  top: calc(50% - 100px);
  left: calc(50% - 50px);

  display: flex;
  justify-content: center;
`;

export default function Spinner(): React.ReactElement {
  return (
    <Wrapper data-testid="spinner">
      <CircularProgress data-testid="circular-progress" size={60} />
    </Wrapper>
  );
}
