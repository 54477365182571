/* eslint-disable */
import { useRoutes, Navigate, useLocation } from 'react-router-dom';
import { Principal } from 'infrastructure/security/model';

import Main from 'application/main';

import LoginContainer from 'application/login';
import DashboardContainer from 'application/main/dashboard';
import UserListContainer from 'application/main/users';
import UserShowContainer from 'application/main/users/show';
import PlanListContainer from 'application/main/plans';
import PlanShowContainer from 'application/main/plans/show';
import ReportsContainer from 'application/main/reports';
import SettingsContainer from 'application/main/settings';
import { useAuthState } from 'infrastructure/security';

type ProtectedRouteProps = {
  element: React.ReactElement;
  logged: boolean;
  principal?: Principal;
  roles?: [];
};
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, logged, principal, roles }) => {
  if (!logged) {
    return <Navigate to="/login" />;
  }
  if (roles && principal) {
    const hasRole = roles.map((it) => principal.roles.indexOf(it) > -1).indexOf(true) > -1;
    if (!hasRole) {
      return <Navigate to="/dashboard" />;
    }
  }
  return element;
};

function routes(url: string, logged: boolean, principal?: Principal): Record<string, unknown>[] {
  return [
    {
      path: '/',
      element: <ProtectedRoute element={<Main />} logged={logged} />,
      children: [
        { path: '/', element: <Navigate to={url === '/' ? '/dashboard' : url} /> },
        { path: '/dashboard', element: <DashboardContainer /> },
        {
          path: '/users',
          children: [
            {
              path: '/users/',
              element: <UserListContainer />,
            },
            {
              path: '/users/:userId',
              element: <UserShowContainer />,
            },
          ],
        },
        { path: '/plans', children: [
            {
              path: '/plans/',
              element: <PlanListContainer />
            },
            {
              path: '/plans/:planId',
              element: <PlanShowContainer />
            },
          ]
        },
        { path: '/reports', element: <ReportsContainer /> },
        { path: '/settings', element: <SettingsContainer /> },
      ],
    },
    {
      path: '/login',
      element: logged ? <Navigate to="/" /> : <LoginContainer />,
    },
  ];
}

export default function Router(): React.ReactElement | null {
  const location = useLocation();
  const { logged, authentication } = useAuthState();
  return useRoutes(
    routes(`${location.pathname}${location.search}`, logged, authentication?.principal)
  );
}
