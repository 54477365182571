import {
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { SyntheticEvent, useState, useCallback } from 'react';
import type { TextInputType } from '../../infrastructure/types/inputs';

const PasswordInputComponent: React.FC<TextInputType> = ({
  label,
  name,
  value,
  id,
  disabled,
  readonly,
  handleFocus,
  handleInput,
  handleChange,
  handleBlur,
}) => {
  const [textValue, setTextValue] = useState(value || '');
  const [showPw, setShowPw] = useState(false);

  const onInput = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      setTextValue(target.value);
      if (handleInput) {
        handleInput(target);
      }
    },
    [handleInput]
  );

  const onFocus = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      if (handleFocus) {
        handleFocus(target);
      }
    },
    [handleFocus]
  );

  const onChange = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      if (handleChange) {
        handleChange(target);
      }
    },
    [handleChange]
  );

  const onBlur = useCallback(
    (e: SyntheticEvent): void => {
      const target = e.target as HTMLInputElement;
      if (handleBlur) {
        handleBlur(target);
      }
    },
    [handleBlur]
  );

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel variant="outlined" htmlFor={id}>
        <span
          style={{ background: '#ffffff', border: '0.4rem solid white', marginLeft: '-0.4rem' }}
        >
          {label}
        </span>
      </InputLabel>
      <OutlinedInput
        id={id}
        name={name}
        type={showPw ? 'text' : 'password'}
        value={textValue}
        disabled={disabled}
        readOnly={readonly}
        onFocus={onFocus}
        onInput={onInput}
        onChange={onChange}
        onBlur={onBlur}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={() => setShowPw(!showPw)}>
              {showPw ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default PasswordInputComponent;
